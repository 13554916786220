.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.minHeight100vh {
  min-height: 100vh;
}

.no-border {
  border:none;
}